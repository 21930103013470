<template>
  <div>
    <section class="detail-hero mb-4">
      <div class="container text-white">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-lg-10 col-md-10 col-sm-12 detail-header-title flex-column">
            <div>
              <h3>{{ `${data.kode} ${data.golongan}` }}&nbsp;
                <a @click.prevent="downloadKBJI" class="btn btn-ternary text-white" :class="{ 'btn-ternary': !unduhDisable , 'btn-secondary': unduhDisable, 'disabled': unduhDisable }">Unduh</a>
              </h3>
              <!-- <h3>{{ data.nama_jabatan }}&nbsp;
                <a @click="generatePdf" class="btn text-white" :class="{ 'btn-ternary': !unduhDisable , 'btn-secondary': unduhDisable, 'disabled': unduhDisable }">Unduh</a>
              </h3> -->
            </div>
            <div style="font-family: arial; margin-bottom: 1rem;">
              <p @click="toKbjiList(links.golonganPokok, 2)" v-show="links.golonganPokok !== '' && level > 1" style="margin: 0px">Golongan Pokok <b><a class="detail-clickable">{{ links.golonganPokok }}</a></b></p>
              <p @click="toKbjiList(links.subGolongan, 3)" v-show="links.subGolongan !== '' && level > 2" style="margin: 0px">Subgolongan Pokok <b><a class="detail-clickable">{{ links.subGolongan }}</a></b></p>
              <p @click="toKbjiList(links.golongan, 4)" v-show="links.golongan !== '' && level > 3" style="margin: 0px">Golongan <b><a class="detail-clickable">{{ links.golongan }}</a></b></p>
              <p @click="toKbjiList(links.jabatan, 5)" v-show="links.jabatan !== '' && level > 4" style="margin: 0px">Jabatan <b><a class="detail-clickable">{{ links.jabatan }}</a></b></p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 text-center detail-header-button">

          </div>
        </div>

        <div class="border-bottom mt-2 mb-4"></div>

        <div class="detail-header-description">
          <!-- <h4 class="fw-medium">Deskripsi Pekerjaan</h4> -->
          <p>

          </p>
        </div>
        <div class="detail-header-example">
          <p>
              <!-- <strong>Contoh judul pekerjaan yang dilaporkan:</strong> Lorem ipsum
    dolor sit amet, consectetur adipiscing elit. Nam at libero at massa
    sodales tincidunt id ut dolor. Phasellus sit amet mi commodo,
    ullamcorper diam iaculis, placerat dui. Phasellus eget nulla sed
    augue rutrum fringilla. Suspendisse non placerat ipsum. -->
          </p>
        </div>
      </div>
    </section>

    <section class="detail-content mb-5">
      <div class="container">
        <div class="card border-0 shadow-lg">
          <div class="card-body">
            <div class="w-100">
              <div class="scroller scroller-left float-start mt-2">
                <i class="bi bi-caret-left-fill"></i>
              </div>
              <div class="scroller scroller-right float-end mt-2">
                <i class="bi bi-caret-right-fill"></i>
              </div>
              <div class="wrapper-nav">
                <nav class="nav nav-pills list mt-2" id="myTab" role="tablist">
                  <a class="nav-item nav-link pointer me-3 active btn-primary" data-bs-toggle="tab"
                    data-bs-target="#tab1" role="tab" aria-controls="public"
                    aria-selected="true"
                  >Deskripsi Tugas</a>
                </nav>
              </div>
              <div class="tab-content p-3" id="myTabContent">
                <div role="tabpanel" class="tab-pane fade active show mt-2" id="tab1"
                  aria-labelledby="public-tab"
                >

                  <!-- Rincian Tugas -->
                  <div class="detail-assignment">
                    <div class="detail-assignment-content">
                      <pre style="text-align:justify;font-family:arial;font-size:12pt">
{{ data.desk }}
                      </pre>
                    </div>
                  </div>

                  <div class="border-bottom my-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <a href="../../assets/documents/kbji.pdf" download class="d-none" ref="downloadKBJI"></a> -->
    <a href="/files/kbji.pdf" download class="d-none" ref="downloadKBJI"></a>
  </div>
</template>

<script>
import api from './api'
import swal from 'sweetalert2'
import auth from '../../helpers/auth.js'

export default {
  data () {
    return {
      data: {
        golongan: '',
        kode: '',
        desk: ''
      },
      links: {
        golonganPokok: '',
        subGolongan: '',
        golongan: '',
        jabatan: ''
      },
      kode: null,
      level: null,
      unduhDisable: true
    }
  },
  mounted () {
    this.getDetailData()
    this.getUserData()
  },
  methods: {
    async getDetailData () {
      const { kode, level } = this.$route.query
      console.log('params', kode, level)
      this.kode = kode
      this.level = level
      let currLevel = 0
      console.log('currLevel logic', level == 0, level)
      if (level !== 'undefined' && level !== null) {
        currLevel = level
      } else {
        if (kode?.split('.').length > 1) {
          currLevel = 5
        } else {
          console.log('kode split', kode.split(''))
          currLevel = kode.split('').length
        }
      }
      console.log('currLevel', currLevel)
      const params = {
        id: kode,
        level: currLevel 
      }
      try {
        const res = await api.getKBJIDetailPhp(params)
        const links = {
          golonganPokok: res.links[0] ? `${res.links[0][0].kode} ${res.links[0][0].golongan}` : '',
          subGolongan: res.links[1] ? `${res.links[1][0].kode} ${res.links[1][0].golongan}` : '',
          golongan: res.links[2] ? `${res.links[2][0].kode} ${res.links[2][0].golongan}` : '',
          jabatan: res.links[3] ? `${res.links[3][0].kode} ${res.links[3][0].golongan}` : ''
        }
        this.data = res.content ? res.content[0] : {
          golongan: '',
          kode: '',
          desk: ''
        }
        this.links = links
      } catch(err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching data'
        })
      }
    },
    toKbjiList (kode, level) {
      let digit = null
      switch (level) {
        case 2:
          digit = kode.substring(0, 1)
          break
        case 3:
          digit = kode.substring(0, 2)
        break
        case 4:
          digit = kode.substring(0, 3)
        break
        case 5:
          digit = kode.substring(0, 4)
        break
      }
      this.$router.push(`/home/kjn_golongan?kode=${digit}&level=${level}`)
    },
    getUserData () {
      const user = auth.getUser()
      if (user && user !== null && user !== undefined && user !== '') {
        this.unduhDisable = false
      }
    },
    async downloadKBJI () {
      if (this.unduhDisable) return ''
      const user = auth.getUser()
      if (user && user !== null && user !== undefined && user !== '') {
        try {
          const params = {
            email: user.email,
            ref: 1,
            token: auth.getSSOToken()
          }

          const res = await api.postDownloadLog(params, { Authorization: `Bearer ${auth.getToken()}` })
          console.log('res', res)
          this.$refs.downloadKBJI.click()
        } catch (err) {
          console.log(err)
          swal.fire({
            icon: 'error',
            title: 'Gagal Unduh KBJI'
          })
        }
      } else {
        window.location.href = `https://account.kemnaker.go.id/auth?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOGIN_URL}&scope=basic email`
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=https://dev.otellogroup.id/&scope=basic email'
        // window.location.href = 'https://account.kemnaker.go.id/auth?response_type=code&client_id=01723b89-180d-436d-abd5-dcab7869547e&redirect_uri=http://localhost:8080/&scope=basic email'
      }
    }
  }
}
</script>

<style>
  @import url('../../assets/styles/detail.css');
  @import url('../../assets/styles/scroller.css');

  .detail-clickable {
    color: white;
    cursor: pointer;
  }
  pre {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
  }

  a {
    color: #fdfdfe;
    text-decoration: underline;
  }
</style>